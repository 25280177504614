import {
  Environment,
  Loader,
  OrbitControls,
  OrthographicCamera,
  Stars,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense, useEffect, useRef, useState } from "react";
import avaStyle from "./AvatarAva.module.css";
import Avatar, {
  SetAvatarPosition,
  SetAvatarPositionDefault,
} from "./avatarUtlity/Avatar";
import Comp from "./avatarUtlity/Comp";
import { useLocation } from "react-router-dom";
import photo_studio_loft_hall_1k from "./images/photo_studio_loft_hall_1k.hdr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegMessage } from "react-icons/fa6";
import {
  faPaperPlane,
  faMicrophone,
  faMicrophoneSlash,
  faUser,
  faUserSlash,
  faStopCircle
} from "@fortawesome/free-solid-svg-icons";
import { IoSend } from "react-icons/io5";
import {
  AudioConfig,
  SpeakerAudioDestination,
  SpeechConfig,
  SpeechSynthesisOutputFormat,
  SpeechSynthesizer,
} from "microsoft-cognitiveservices-speech-sdk";
import blendShapeNames from "./avatarUtlity/blendShapeNames.json";
import Question from "./exampleQuestion/Question";
import modelGlb1 from "./modelsAndRelated/model.glb";
import { v4 as uuidv4 } from "uuid";
import { GrPowerReset } from "react-icons/gr";
import SpeechRecognition, { useSpeechRecognition, } from "react-speech-recognition";
import { getOrg } from "./org";
import { GrAttachment } from "react-icons/gr";
import { IoCameraSharp } from "react-icons/io5";
import { GrGallery } from "react-icons/gr";
import { RxCross1 } from "react-icons/rx";
import { FaPencilAlt } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { IoCopyOutline } from "react-icons/io5";
import { MdDone } from "react-icons/md";
import { pointer } from "@testing-library/user-event/dist/cjs/pointer/index.js";
export default function AvatarAva() {
  
  let className;
  let modelGlb;
  let apiUrl;
  let org;
  //var userId;
  let background;
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orgDmType = atob(searchParams.get("orgd"));
  // console.log("orgDmType:" + orgDmType);
  const {
    isMicrophoneAvailable,
    transcript,
    listening,
    finalTranscript,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [userId, setUserId] = useState("");
  const [checkedAvatarAccess, setCheckedAvatarAccess] = React.useState(false);
  const AZURE_REGION =
    process.env.REACT_APP_AZURE_REGION || "AZURE_REGION_None";
  const AZURE_KEY = process.env.REACT_APP_AZURE_KEY || "AZURE_KEY_None";
  const openAIEndpoint = process.env.REACT_APP_OPEN_AI_ENDPOINT;
  const authToken = process.env.REACT_APP_AI_AUTHTOKEN;
  const [blendDataState, setBlendDataState] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const chatMsgContainerRef = useRef(null);
  const { state } = useLocation();
  const [geoLocation, setGeoLocation] = useState(null);
  const [isMicOn, setMic] = useState(false);
  const [showDoneIcon, setShowDoneIcon] = useState(false);
  const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);
  if (state) {
    // code for chat.aihuman
    className = state.view;
    modelGlb = state.glbModel;
    apiUrl = state.url;
  } else {
    className = "main";
    modelGlb = modelGlb1;
    //apiUrl = apiUrl//process.env.REACT_APP_SIRONA_URL;
  }
  // console.log(process.env.REACT_APP_dc_bluetreedental)
  // console.log("HOSTNAME: " + window.location.hostname);
  const orgDetails = getOrg(orgDmType);
  //console.log(orgDetails);
  org = orgDetails.org;
  apiUrl = orgDetails.apiUrl;
  background = orgDetails.background;

  const [showModal, setShowModal] = useState(false);
  const [AvatarModel, setAvatarModel] = useState(
    <Avatar avatar_url={modelGlb} setShowModal={setShowModal} />
  );
  const [isLoaderVisible, setLoaderIsVisible] = useState(false);

  const recognition = useRef(
    new (window.SpeechRecognition || window.webkitSpeechRecognition)()
  );
  const [isListening, setIsListening] = useState(false);
  const [logo, setLogo] = useState("");
  const [exampleques, setExampleQues] = useState([]);
  const [quesClass, setQuesClass] = useState("questionsHidden");
  const [isExmBtnVisible, setExmBtnVisible] = useState(true);
  // const [isChatBtnVisible, setChatBtnVisible] = useState(false);
  // const [isQuerySendBycSlick, setQuerySendBycSlick] = useState(false);
  let isQuerySendBycSlick=false;
  /**
   * text to speech config
   */
  //-----------------------------------------------------------------------------------
  const speakerAudio = new SpeakerAudioDestination();
  const audioConfigurations = AudioConfig.fromSpeakerOutput(speakerAudio);
  const speechConfigurations = SpeechConfig.fromSubscription(
    AZURE_KEY,
    AZURE_REGION
  );
  speechConfigurations.speechSynthesisOutputFormat =
    SpeechSynthesisOutputFormat.Audio16Khz64KBitRateMonoMp3;
  speechConfigurations.speechSynthesisLanguage = "en-US";
  speechConfigurations.speechSynthesisVoiceName = "en-US-JennyNeural";
  const synthesizer = new SpeechSynthesizer(
    speechConfigurations,
    audioConfigurations
  );
  // ---------------------------------------------------------------------------------
  useEffect(() => {
    if (finalTranscript !== '' && isQuerySendBycSlick == false) {
      console.log("end");
      sendAutomessage(finalTranscript);
    }
  }, [finalTranscript]);

  function sendAutomessage(finalTranscripts){
    let finalTranscript=finalTranscripts;
    setTimeout(() => {
      SpeechRecognition.stopListening();
      if(isMicOn==true && isQuerySendBycSlick == false && isLoaderVisible!=true){
        
        // sendTextQuery();
        let htmlContent = null;
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { type: "send", text: finalTranscript, htmlContent },
        ]);
        setTextAreaValue("");
        textareaRef.current.style.height = 'auto';      
        sendToAigs(finalTranscript);
       
      }
    }, 10);
   
  }

  function startMic() {
    if (browserSupportsSpeechRecognition) {
      if (isMicrophoneAvailable) {
        setMic(true)
        resetTranscript();
        SpeechRecognition.startListening({ continuous: true });
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        textareaRef.current.focus();//30-03-24
        }
        
      } else alert("Please allow mic access!");
    } else alert(`Browser does'nt support speech recognition`);
  }
  function stopMic() {
    setMic(false)
    SpeechRecognition.stopListening();
  }
  function handlerPosition() {
    if (className !== "main") {
      SetAvatarPosition();
    }
  }
  useEffect(() => {
    setTextAreaValue(transcript);
    // console.log(transcript);
  }, [transcript]);
  useEffect(() => {
    if(checkedAvatarAccess){// chnge
    setAvatarModel(
      <Avatar
        avatar_url={modelGlb}
        blendDataState={blendDataState}
        setShowModal={setShowModal}
        currentOrg={org}
      />
    );
    }
  }, [blendDataState]);
  // const textAreaRef = useRef(null);
  const initialized = useRef(false)
  useEffect(() => {
    
    if (showModal) {
      console.log("Model is visible...");
     
      showGreetingOnLoad();
    }else {
      showGreetingOnLoad();
    }
    
  }, [showModal]);

  function showGreetingOnLoad(){
    if (!initialized.current) {
      // avatarChatSizeChange()
      initialized.current = true
      if(showModal==false){//chnge
        initiateGreetings(true);
      }
    }
  }

  function initiateGreetings(firstgreet = false) {
   // console.log("firstgreet:" + firstgreet);
  
    if (org == "org_3_helthcare_assistant") {
      setUserId("a8716d42-b042-477c-9d71-d05443a0ba12");
      getGreetingMessages("a8716d42-b042-477c-9d71-d05443a0ba12", firstgreet);
    } else {
      let Uid = uuidv4();
      if (userId != "" && Uid) {
      }
      console.log("Uid:" + Uid);
      setUserId(Uid);
      getGreetingMessages(Uid, firstgreet);
    }
  }

  // Function to update the state when the textarea value changes
  const textareaRef = useRef(null);
  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  };

  // adjust height of textarea
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  };

  // Effect to initialize textarea height and attach event listener
  useEffect(() => {
    adjustHeight();
  }, [textAreaValue]);

  function textToSpeech(textToVesime) {
    let promptForAzure = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xml:lang="en-US">
			<voice name="en-US-JennyNeural">
				<mstts:viseme type="FacialExpression"/>
				<prosody volume="loud">${textToVesime}</prosody>
			</voice>
		</speak>`;

    // let promptForAzure = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xml:lang="en-US">
    // <voice name="en-US-JennyNeural" role="YoungAdultFemale" style="assistant">
    //     <mstts:viseme phoneme=":-)" type="FacialExpression"/>
    //     <prosody  volume="medium">${textToVesime}</prosody>
    // </voice>
    // </speak>`;
    let blendData = [];
    let timeStep = 1 / 60;
    let timeStamp = 0;

    synthesizer.synthesisStarted = (sender, event) => {
      console.log("Collecting the audio and vesime");
      speakerAudio.pause();
    };

    synthesizer.visemeReceived = (s, e) => {
      // `Animation` is an xml string for SVG or a json string for blend shapes
      let animation = JSON.parse(e.animation);
      animation?.BlendShapes?.forEach((blendArray) => {
        let blend = {};
        blendShapeNames.forEach((shapeName, shapeNameIndex) => {
          blend[shapeName] = blendArray[shapeNameIndex];
        });
        blendData.push({
          time: timeStamp,
          blendshapes: blend,
        });

        timeStamp += timeStep;
      });
    };

    speakerAudio.onAudioEnd = () => {
      //debugger
      console.log("Audio ends, now stop speaking");
      setButtonDisabled(false);
      micControl();
    };

    synthesizer.synthesisCompleted = (sender, event) => {
      console.warn("Collection completed, now we speak and animate model");
      setBlendDataState(blendData);
      speakerAudio.resume();
    };

    // Starting point - send data to azure
    synthesizer.speakSsmlAsync(
      promptForAzure,
      (result) => {
        synthesizer.close();
        if (result.privErrorDetails) {
          setButtonDisabled(false);
        }
        console.log("speakSsmlAsync, result", result);
      },
      (error) => {
        setButtonDisabled(false);
        console.error(error.message);
        synthesizer.close();
      }
    );
  }

  const handleSpeakButton = async () => {
    // onclick speak button sent text
    if (listening) {//30-03-24
      // setQuerySendBycSlick(true)
      isQuerySendBycSlick=true;
      SpeechRecognition.stopListening();
    }
    sendTextQuery();
  };

  const handleEnterClickAndArrowUp = (event) => {
    
    if (listening) {
      SpeechRecognition.stopListening();
      //  textareaRef.current.focus();
    }
    if (event.key == "Enter") {
     
      event.preventDefault(); // Prevent the default behavior of Enter key
      sendTextQuery();
    }

    // arrow up functionality
    let sentMessages=chatMessages.filter(obj => obj.type === "send").map(obj => obj.text);

    clearTimeout(arrowTimeoutRef.current)
  
    if (event.key === 'ArrowUp') {
      if (messageIndexRef.current === -1) {
        messageIndexRef.current = sentMessages.length
      }
  
      messageIndexRef.current = Math.max(messageIndexRef.current - 1, 0)
    } else if (event.key === 'ArrowDown') {
      if (messageIndexRef.current === -1) {
        return
      }
  
      messageIndexRef.current = Math.min(
        messageIndexRef.current + 1,
        sentMessages.length
      )
    }
    if(sentMessages[messageIndexRef.current]){
    console.log('sentMessages:'+sentMessages[messageIndexRef.current]);
    setTextAreaValue(sentMessages[messageIndexRef.current])
    }
  
    arrowTimeoutRef.current = setTimeout(() => {
      messageIndexRef.current = -1 // Reset message index after a certain timeout
    }, 1000) // Reset after 1 second

  };

  const sendTextQuery = () => {
    console.log("Textarea value:", textAreaValue);
    // setQuesClass("questionsHidden");
    if(textAreaValue!= undefined ){
    if (textAreaValue.trim() !== "") {
      let query = textAreaValue;
      //setRequestText(textAreaValue)
      let htmlContent = null;
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { type: "send", text: textAreaValue, htmlContent },
      ]);
      textareaRef.current.style.height = 'auto';      

      sendToAigs(query);
      setTextAreaValue("");
    }
  }
  };

  function micControl(){
    if(isMicOn===true){
      startMic();
    }
  }
  async function getGreetingMessages(uid, firstgreet) {
    setLoaderIsVisible(true);
    chatMsgContainerRef.current.scrollTop = chatMsgContainerRef.current.scrollHeight;
     let url="";
      url=`${process.env.REACT_APP_BACKEND}/chatAction?cmd=START&orgId=${org}&userId=${uid}`
    console.log(`geoLocationgreert:${geoLocation}`)

    setUserId(uid);
    fetch(
      url,
      { method: "GET" }
    )
      .then(async (response) => {
        if (response.ok) {
          let data = await response.text();
          data = JSON.parse(data);
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { type: "receive", text: data.GREETING },
          ]);

          if(data.GUIDANCE)
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { type: "guidance", text: data.GUIDANCE },
          ]);
          setExampleQues(data.EXAMPLE_QUESTIONS);
          setLogo(data.CORPORATE_LOGO_URL);
          if (checkedAvatarAccess) {
            textToSpeech(data.GREETING);
          }
        setLoaderIsVisible(false);
        setButtonDisabled(false);
          //
        } else{
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { type: "receive", text: "Error:Invalid API Key" },
          ]);
        setButtonDisabled(false);
        setLoaderIsVisible(false);
        }
      })
      .catch((error) => {
        console.error("Error in execution: " + error.message);
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { type: "Error", text: `Error in execution` },
        ]);
        setButtonDisabled(false);
        setLoaderIsVisible(false);
      });
  }

  const EndConversation = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/chatAction?userId=${userId}&userName=Sylvie+Silverstone&orgId=${org}&companyName=Mapple&cmd=FINISH`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        // Handle non-JSON response here
        const text = await response.text();
        console.log("Non-JSON response:", text);
        stopMic();
        setMic(false);
        setSpinIconClass(null);
        setChatMessages([]);
        initiateGreetings();
        setButtonDisabled(false);
        setTextAreaValue('');
      } else {
        console.error("Error in execution: ", response.status);
        setSpinIconClass(null);
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { type: "Error", text: `${response.status},Conversation reset encountered an error` },
        ]);
        setButtonDisabled(false);
      }
    } catch (error) {
      console.error("Error in execution: ", error.message);
      setSpinIconClass(null);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { type: "Error", text: `Conversation reset encountered an error` },
      ]);
      setButtonDisabled(false);
    }
  };
 
  useEffect(() => {
    const handleResize = () => {
      if(showModal){
      if (window.innerWidth < 500) {
        //if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        if (className == "main") {
          //avar size small in chat mode only
          SetAvatarPosition();
        }
      } 
      else {

        SetAvatarPositionDefault();
        
      }
    }
    };
    const handleTabClose = async (event) => {
      event.preventDefault();

      // Call your API endpoint to end the conversation
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/chatAction?userId=${userId}&userName=Sylvie+Silverstone&orgId=${org}&companyName=Mapple&cmd=FINISH`,
          {
            method: "GET",
          }
        );

        if (response.ok) {
          // Handle non-JSON response here
          const text = await response.text();
          console.log("Non-JSON response:", text);
          return "";
        } else {
          console.error("Error in execution: ", response.status);
        }
      } catch (error) {
        console.error("Error in execution: ", error.message);
      }

      // Optionally, return a message to the browser

      //event.returnValue = ''; // Clear the default message
    };

    window.addEventListener("resize", handleResize);
    //window.addEventListener('beforeunload', handleTabClose);
    
    return () => {
      //window.removeEventListener('beforeunload', handleTabClose);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sendToAigs = async (query) => {
    // let loc = await updateGeoLocation();
     //debugger
    // console.log(`geoLocation:${geoLocation}`)
    setButtonDisabled(true);
    setLoaderIsVisible(true);
    try {
      //console.log(`${apiUrl}&&userId=${userId}&query=${query}`)
      const response = await fetch(
        `${apiUrl}&userId=${userId}&query=${query}`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        // debugger
        // Check if the response content type is JSON
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          //(data);

          const htmlContent = extractHtml(data);
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { type: "receive", text: data, htmlContent },
          ]);
          
          micControl();
          //setQuerySendBycSlick(false);
          isQuerySendBycSlick=false;
          setLoaderIsVisible(false);
          setButtonDisabled(false);
        } 
        else {
         
          // If not JSON, you might handle other content types here
          let data = await response.text();
          if (data.includes("QUERY_WITH_PROMPT")) {
            data = JSON.parse(data);
            var queryWithPrompt = data["QUERY_WITH_PROMPT"];
            if (org == "org_5_ai_human") {
              queryWithPrompt = queryWithPrompt
                .replace("[INST]", "")
                .replace(/\n\n/g, "");
              queryWithPrompt = queryWithPrompt.replace("[/INST]", "");
              console.log(queryWithPrompt);
              const htmlContent = extractHtml(queryWithPrompt);
              if (checkedAvatarAccess) {
                textToSpeech(queryWithPrompt);
              }else{
                micControl();
              }
              setChatMessages((prevMessages) => [
                ...prevMessages,
                { type: "receive", text: queryWithPrompt, htmlContent },
              ]);
            } else {
              getOpenAIResponse(queryWithPrompt);
            }
            //setQuerySendBycSlick(false);
            isQuerySendBycSlick=false;
            setLoaderIsVisible(false);
            setButtonDisabled(false);
          } 
          else {
            //   const htmlContent = extractHtml(data);// chnge on 15 march 24
            const { html, texts } = extractHtmlAndText(data);
            // debugger html, texts
            const htmlContent = data; //html;
            let text = texts;
            if (text.includes("&")) {
              text=text.replaceAll("&", "and");
            }
            if (checkedAvatarAccess && text != null && text != "") {
              //&& text!=null && text!=""
              textToSpeech(text);
            } else {
              setButtonDisabled(false);
              micControl();
            }
            setChatMessages((prevMessages) => [
              ...prevMessages,
              { type: "receive", text: data, htmlContent },
            ]);
            setLoaderIsVisible(false);
           
            //setQuerySendBycSlick(false);
            isQuerySendBycSlick=false;
            // micControl();
          }
        }

        // Assuming set HitAzure is a state-setting function
      }
       else {
        console.error("Error in execution: ", response);
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { type: "Error", text: `${response.status},Error in execution` },
        ]);
        setButtonDisabled(false);
        setLoaderIsVisible(false);
        micControl();
        //setQuerySendBycSlick(false);
        isQuerySendBycSlick=false;
      }
    }
     catch (error) {
      console.error("Error in execution: ", error.message);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { type: "Error", text: error.message },
      ]);
      setLoaderIsVisible(false);
      setButtonDisabled(false);
      micControl();
      //setQuerySendBycSlick(false);
      isQuerySendBycSlick=false;
    }
  };

  function extractHtml(response) {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    const match = response.match(htmlRegex);
    return match ? match[0] : null;
  }

  function extractHtmlAndText(response) {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    const match = response.match(htmlRegex);
    const html = match ? match[0] : null;
    let texts = html ? response.replace(html, "").trim() : response.trim();
    // Remove "<!DOCTYPE html>" from texts if present
    if (texts && texts.includes("<!DOCTYPE html>")) {
      texts = texts.replace("<!DOCTYPE html>", "").trim();
    }
    return { html, texts };
  }
  const handleMicClick = () => {
    if (listening) {
      stopMic();
    } else {
      startMic();
    }
  };

  const handleAvatarSpeakClick = () => {
    setCheckedAvatarAccess(!checkedAvatarAccess);
    setShowModal(!checkedAvatarAccess)//
    setBlendDataState([])
  };

  if (recognition.current) {
    recognition.current.onresult = (event) => {
      const spokenText = event.results[0][0].transcript;
      setTextAreaValue(spokenText.substring(0, 200));
    };

    recognition.current.onend = () => {
      setIsListening(false);
    };
  }

  useEffect(() => {
    // Scroll to the bottom when chat messages change or loader is visible
    chatMsgContainerRef.current.scrollTop =
      chatMsgContainerRef.current.scrollHeight;
  }, [chatMessages, isLoaderVisible]);

  const handleQuestionClick = (question) => {
    //console.log("Selected Question:", question);
    setTextAreaValue(question);
    setQuesClass("questionsHidden");
    setExmBtnVisible(true);
    // Do whatever you want with the selected question
  };

  const getOpenAIResponse = async (prompt) => {
    try {
      const res = await fetch(openAIEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "user",
              content: prompt,
            },
          ],
          temperature: 0,
          stream: false,
        }),
      });

      if (!res.ok) throw new Error("Failed to fetch data from OpenAI API.");

      let data = await res.json();
      data = data.choices[0].message.content.replace("<<SYS>>", "");
      if (data.includes("&")) {
        data.replaceAll("&", "and");
      }
      console.log(data);
      const htmlContent = extractHtml(data);
      if (checkedAvatarAccess) {
        textToSpeech(data);
      } else {
        setButtonDisabled(false);
        micControl();
      }
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { type: "receive", text: data, htmlContent },
      ]);
      setLoaderIsVisible(false);
    } catch (err) {
      console.error(err);
      micControl();
    }
  };

  function setQuestionsClass() {
    setQuesClass("questionsVisible");
    setExmBtnVisible(false);
  }
  function unsetQuesionsClass() {
    setQuesClass("questionsHidden");
    setExmBtnVisible(true);
  }
  const [spinIconClass, setSpinIconClass] = useState(null);
  function resetConversation() {
    setSpinIconClass("rotateResetIcon");
   
    EndConversation();
  }

// camera start
const [cameraState, setCameraState] = useState(null);
const [cameraStream, setCameraStream] = useState(null);
const [capturedImage, setCapturedImage] = useState(null);
const cameraContainerRef = useRef(null);
const videoElementRef = useRef(null);

const captureImage = () => {
  if (videoElementRef.current) {
    const canvas = document.createElement("canvas");
    canvas.width = videoElementRef.current.videoWidth;
    canvas.height = videoElementRef.current.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      videoElementRef.current,
      0,
      0,
      canvas.width,
      canvas.height
    );
    const base64Image = canvas.toDataURL("image/jpeg");
    setCapturedImage(base64Image);
   // console.log(capturedImage);
  }
};

useEffect(() => {
  console.log("capturedImage:"+capturedImage);
}, [capturedImage]);

const stopCameraStream = () => {
  if (cameraStream) {
    setCameraState(null);
    cameraStream.getTracks().forEach(track => track.stop());
    setCameraStream(null);
  }
};
    const startCameraStream = async () => {
      setCameraState('displayCameraContainer');
      try {
        // const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });

        if (!videoElementRef.current) {
          const videoElement = document.createElement("video");
          videoElement.srcObject = stream;
          videoElement.autoplay = true;
          videoElement.playsInline = true;
          videoElementRef.current = videoElement;
        } else {
          videoElementRef.current.srcObject = stream;
        }
        setCameraStream(stream);

        if (cameraContainerRef.current ) {
          // cameraContainerRef.current.innerHTML = "";
          cameraContainerRef.current.appendChild(videoElementRef.current);
        } else {
          console.error("Camera stream container reference not found.");
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };
    // const handleIconPosition = () => {
    //   const videoElementRect = videoElementRef.current.getBoundingClientRect();
    //   const iconTop = videoElementRect.top;
    //   const iconRight = videoElementRect.right;
    //   return { top: iconTop, right: iconRight };
    // };

    // startCameraStream();

    // return () => {
    //   const videoElement = cameraContainerRef.current.querySelector("video");
    //   if (videoElement) {
    //     videoElement.srcObject.getTracks().forEach(track => track.stop());
    //     videoElement.remove();
    //   }
    // };

    // Upload image 
    const [imageBase64, setImageBase64] = useState(null)
    const inputRef = useRef(null);
    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setImageBase64(base64String);
        };
        reader.readAsDataURL(file);
      }
    
      // Clear input value to allow selecting the same file again
      //event.target.value = null;
    };
    
    useEffect(() => {
      if(imageBase64 != null){
      console.log("imageBase64:"+imageBase64);//set imageBase64 null after use
      }
    }, [imageBase64]);

    function handleClick(){
      inputRef.current.value = null;
      inputRef.current.click();
    }

    function updateGeoLocation(){
      return new Promise((resolve)=>{
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setGeoLocation({ 
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });

          let locations={ 
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }
          resolve(locations)
        }, () => {
          console.log('Error getting location');
          resolve("null")
        });
    
      } else {
        console.log('Geolocation is not supported by this browser.');
        resolve("null")
      }
    })
    }
// key up functionality
// const sentMessages = [
//   'hi',
//   'how are you',
//   'what are you doing today',
//   'i will meet 5 o clock',
//   'bye'
// ]
// const [inputText, setInputText] = useState('')
const messageIndexRef = useRef(-1) // Start from the last message (-1 indicates no message selected)
const arrowTimeoutRef = useRef(null)


const handleKeup = (event) => {
  //console.log(chatMessages)
  
  let sentMessages=chatMessages.filter(obj => obj.type === "send").map(obj => obj.text);

  clearTimeout(arrowTimeoutRef.current)

  if (event.key === 'ArrowUp') {
    if (messageIndexRef.current === -1) {
      messageIndexRef.current = sentMessages.length
    }

    messageIndexRef.current = Math.max(messageIndexRef.current - 1, 0)
  } 
  else if (event.key === 'ArrowDown') {
    if (messageIndexRef.current === -1) {
      return
    }

    messageIndexRef.current = Math.min(
      messageIndexRef.current + 1,
      sentMessages.length
    )
  }
  if(sentMessages[messageIndexRef.current]){
  console.log('sentMessages:'+sentMessages[messageIndexRef.current]);
  setTextAreaValue(sentMessages[messageIndexRef.current])
  }

  arrowTimeoutRef.current = setTimeout(() => {
    messageIndexRef.current = -1 // Reset message index after a certain timeout
  }, 1000) // Reset after 1 second
}

const copyToClipboard = (text, index) => {
  setShowDoneIcon(true);
  navigator.clipboard.writeText(text)
    .then(() =>{
      console.log('Text copied to clipboard:', text)
      setCopiedMessageIndex(index);
      setTimeout(()=>{
        setCopiedMessageIndex(null);
        setShowDoneIcon(false);
      },500)
    })   
    .catch((error) => console.error('Error copying text to clipboard:', error));
    
};

// check for li in response

// function renderHTMLContent(htmlContent, messageType) {
//   if (messageType === 'receive' && htmlContent.includes('<li>')) {
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(htmlContent, 'text/html');
//     const liElements = doc.querySelectorAll('li');
//     liElements.forEach((li) => {
//       li.onclick = () => setTextAreaValue(li.innerHTML);
//     });

//     return (
//       <div className={avaStyle.htmlData}>
//         {Array.from(liElements).map((li, index) => (
//           <li key={index} > {li.innerHTML} <GoPencil onClick={() => setTextAreaValue(li.innerHTML)} /></li>
//         ))}
//       </div>
//     );
//   }

//   const parser = new DOMParser();
//   const doc = parser.parseFromString(htmlContent, 'text/html');
//   const elements = doc.querySelectorAll('*');
//   elements.forEach((element) => {
//     if (element.tagName.toLowerCase() === 'li') {
//       element.onclick = () => setTextAreaValue(element.innerHTML);
//     }
//   });

//   return (
//     <div className={avaStyle.htmlData} dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />
//   );
// }

// function downloadConversation(){
//   const array  = chatMessages;
//   let tableContent = '<ul>';
//   tableContent = '';

//   array.forEach(message => {
//     tableContent += `<li>${message.type} <ul><li>${message.text}</li></ul></li>`;
//   });

//   tableContent += '</ul>';

//   // Creating the blob and downloading the file
//   const blob = new Blob([tableContent], { type: 'text/html' });
//   const url = URL.createObjectURL(blob);
//   const link = document.createElement('a');
//   link.download = 'conversation.html';
//   link.href = url;
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// }


// ----------------------------------------
  return (
    <div className={`full ${avaStyle[className]}`}>
      { checkedAvatarAccess &&(<Canvas
        dpr={2}
        onCreated={(ctx) => {
          ctx.gl.physicallyCorrectLights = true;
        }}
      >
        <OrbitControls
          enableZoom={true}
          minZoom={1000} // Minimum zoom level
          maxZoom={2200} // Maximum zoom level
          minAzimuthAngle={-Math.PI / 9} // right
          maxAzimuthAngle={Math.PI / 9} // left
          maxPolarAngle={Math.PI / 1.55} // down
          minPolarAngle={Math.PI / 3} // up *
          target={[0, 1.65, 1]}
        />
        <Stars />
        <OrthographicCamera makeDefault zoom={2000} position={[0, 1.65, 1]} />
        <Suspense fallback={null}>
          <Environment background={false} files={photo_studio_loft_hall_1k} />
        </Suspense>
        <Suspense fallback={null}>
          <Comp organization={background} />
        </Suspense>
        <Suspense fallback={null}>{AvatarModel}</Suspense>
      </Canvas>)}
      {/* <Loader dataInterpolation={(p) => `Loading... please wait`} /> */}

      {/* { logo !== "" &&  (
  <div className={avaStyle.logoOrg}>
    <img src={logo} alt="Logo" />
  </div>
)} */}

      <div className={`${avaStyle.chatContainer} ${ !checkedAvatarAccess ? avaStyle.withBackground : ''}`}>
        {/* <div className={avaStyle.chatHeightview}> */}
        <div className={`${checkedAvatarAccess ? avaStyle.mainChatHeightViewWithAvatar : avaStyle.mainChatHeightViewWithOutAvatar} ${ className ? avaStyle.fullmainChatHeightViewWithAvatar : ''}`}>
          <div className={avaStyle.chatMsgContainer} ref={chatMsgContainerRef}>
          <ul className={avaStyle.chatElement}>
  {chatMessages.map((message, index) => (
    <li
      key={index}
      className={`slide-up ${avaStyle[message.type] || avaStyle.error}`}
    >
      {!message.htmlContent && (
        <>
          {message.text}{' '}
          {message.type === 'send' && (
            <div className={avaStyle.copyNEdit}>
              <span className={avaStyle.copyIcon} title="copy">
                {showDoneIcon && copiedMessageIndex === index ? (
                  <MdDone />
                ) : (
                  <IoCopyOutline onClick={() => { copyToClipboard(message.text, index); }} />
                )}
              </span>{' '}
              <span className={avaStyle.editIcon} title="Edit">
                <GoPencil onClick={() => setTextAreaValue(message.text)} />
              </span>
            </div>
          )}
        </>
      )}
      {message.htmlContent && (
  <div
    dangerouslySetInnerHTML={{
      __html: message.htmlContent.replace(/<li/g, '<li title="Click to edit" style="cursor:pointer" onMouseEnter="this.style.backgroundColor=\'#f5f5f5\'" onMouseLeave="this.style.backgroundColor=\'\'"')
    }}
    onClick={(event) => {
      const target = event.target;
      if (target.tagName === 'LI') {
        console.log(target.innerHTML);
        setTextAreaValue(target.innerHTML);
      }
    }}
    style={{ cursor: 'default' }}
  />
)}



    </li>
  ))}
</ul>

{isLoaderVisible && (
              <div className={avaStyle.loader1} id={avaStyle.waitingForResp}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
          </div>
          <div className={avaStyle.speakText}>
            <div className={avaStyle.actionIcons}>
            <button
                disabled={isButtonDisabled}
                title={`${ !checkedAvatarAccess ? "Enble Avatar" : "Disable Avatar"}`}
                className={`${avaStyle.speakButton} ${
                  isButtonDisabled ? avaStyle.disabledButton : ""
                } ${avaStyle.circledIcons}`}
                onClick={handleAvatarSpeakClick}
              >
                <FontAwesomeIcon
                icon={checkedAvatarAccess ? faUser : faUserSlash} style={{height:'18px'}}
                />{" "}
              </button>
              
              <button
                className={`${avaStyle.speakButton} ${
                  isButtonDisabled ? avaStyle.disabledButton : ""
                }`}
                disabled={isButtonDisabled}
                title="Reset Conversation"
                onClick={resetConversation}
              >
                {" "}
                <GrPowerReset
                  size={25}
                  className={`${avaStyle[spinIconClass]}`}
                />{" "}
              </button>
              {/* <button className={avaStyle.speakButton} onClick={downloadConversation}>Download</button> */}
              <textarea className={avaStyle.inputTxtArea} value={textAreaValue} onChange={handleTextAreaChange} placeholder="Enter text here..."  onKeyDown={handleEnterClickAndArrowUp} ref={textareaRef} />
                         <button
                className={`${avaStyle.speakButton} ${
                  isButtonDisabled ? avaStyle.disabledButton : ""
                } ${avaStyle.attachIcon}`}
                disabled={isButtonDisabled}
                title="Attach file"
                style={{border: "none", position:"relative"}}
              >
                {" "}
                <GrAttachment size={25} />{" "}

              <div className={avaStyle.cameraNuploadIcons}>
               <div className={avaStyle.singleIconContain} onClick={()=> { 
                //startCameraStream(); 
                }} style={{borderBottom: "1px solid #ccc"}}>
                <IoCameraSharp title="Camera" size={25} color="white" className={`${avaStyle[spinIconClass]}`} style={{height: "25px", width: "100%", cursor:'not-allowed' }}  />
           </div>
        <input
        ref={inputRef}
        // type="file"
        // accept="image/*"
        // onChange={handleImageUpload}
        style={{ display: 'none'}}
      />  
      <div className={avaStyle.singleIconContain}>
                <GrGallery title="Upload"  onClick={handleClick} type="file" size={25} color="white" className={`${avaStyle[spinIconClass]}`} style={{height: "25px", width: "100%", cursor:'not-allowed' }}  />
              </div>
               </div>

              </button>
              
            </div>   
                <button
                disabled={isButtonDisabled}
                title={`${ listening ? "Disable Mic" : "Enable Mic"}`}
                className={`${avaStyle.speakButton} ${
                  isButtonDisabled ? avaStyle.disabledButton : ""
                } ${avaStyle.circledIcons}`}
                onClick={handleMicClick}
              >
                <FontAwesomeIcon
                  icon={listening ? faStopCircle : faMicrophone}
                  className={listening ? avaStyle.micListeningTrue : ""}
                  size="xs"
                />{" "}
              </button>

              <button
                title="Send Message"
                className={`${avaStyle.speakButton} ${
                  isButtonDisabled ? avaStyle.disabledButton : ""
                } ${avaStyle.circledIcons}`}
                disabled={isButtonDisabled}
                onClick={() => handleSpeakButton()}
              >
                {" "}
                <IoSend size={20} />{" "}
              </button>

          </div>
        </div>
        <Question
          exampleQues={exampleques}
          onQuestionClick={handleQuestionClick}
          quesTionClass={quesClass}
        />
        <div className={avaStyle.exampleContainer}>
          <button
            className={`${avaStyle.speakButton} ${
              isButtonDisabled ? avaStyle.disabledButton : ""
            }`}
            disabled={isButtonDisabled}
            title="Reset Conversation"
            style={{ border: "none", padding: 0, margin: 0 }}
            onClick={resetConversation}
          >
            {" "}
            <GrPowerReset size={25} className={`${avaStyle[spinIconClass]}`} />{" "}
          </button>
            {quesClass !== "questionsVisible" && (
            <FontAwesomeIcon
              color="white"
              icon={checkedAvatarAccess ? faUser : faUserSlash}
              onClick={handleAvatarSpeakClick}
              className={avaStyle.avaEnableDisable}
              title={`${ !checkedAvatarAccess ? "Enble Avatar" : "Disable Avatar"}`}
            />
          )}

          {/* <GrPowerReset size={25} color="white" onClick={resetConversation} className={avaStyle[spinIconClass]} /> */}
          {!isExmBtnVisible && (
            <FaRegMessage
              size={25}
              color="white"
              style={{ cursor: "pointer" }}
              onClick={unsetQuesionsClass}
            />
          )}
          {isExmBtnVisible && (
            <button
              className={avaStyle.showQuesBtn}
              onClick={setQuestionsClass}
            >
              Examples
            </button>
          )}
        </div>
      </div>
       <div className={`${avaStyle.cameraStreamContainer} ${avaStyle[cameraState]}`}>
      <div ref={cameraContainerRef} className={avaStyle.videoNcross}>
      <RxCross1 id="crossIcon" className={avaStyle.crossVideoIcon} onClick={()=>{
        stopCameraStream();
      }} />
         </div>
         <div className={avaStyle.captureBtn}>
      
         <IoCameraSharp color="black" className={`${avaStyle[spinIconClass]}`} style={{height: "65px", width: "65px", border: "2px solid #000", borderRadius: "50%", padding: "12px", cursor: "pointer"}} 
         onClick={()=>{
          captureImage();
         }}
         
         />
         </div>
      </div>
    </div>
  );
}
