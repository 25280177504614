import React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import modelGlb1 from '../modelsAndRelated/model.glb';
import allAvatarList from "./allAvatarList.json";
import "./avatarList.css";
import logo from "./images/logo.png";
import { RxOpenInNewWindow } from "react-icons/rx";
import { BsChatDotsFill } from "react-icons/bs";

function AvatarList() {
	const navigate = useNavigate();
	const handleAvatarClick = (view, bottype) => {
		let url = "";
		if (bottype === "Healthbot") {
			url = process.env.REACT_APP_SIRONA_URL;
		} else
			url = process.env.REACT_APP_SIRONA_URL;

		navigate('./avatars/eva', {
			state: {
				view: view,
				//bottype: bottype,
				url: url,
				glbModel: modelGlb1
			}
		});
	};
	return (
		<div className="container">
			<div className="header">
				<img className="logoImg" src={logo} alt="" />
				<h1>Our Avatars</h1>
			</div>
			<div className="avatarContainer">
			<div className="avatarCard">
					<div className="imageConatiner"><img src={allAvatarList[3].thumbnailPath} alt={allAvatarList[3].name} /></div>
					<div className="detailsOfAvatar">
						<div>
							<h4>{allAvatarList[3].name}</h4>
						</div>
						<div className="buttonsContainer">
						<button className='navLinksView'> <NavLink path="relative" to="https://dentalclinic.aihumans.ai"><p>Chat</p><BsChatDotsFill size={20} color='white' /></NavLink> </button>
						</div>
					</div>
				</div>
				<div className="avatarCard">
					<div className="imageConatiner"><img src={allAvatarList[4].thumbnailPath} alt={allAvatarList[4].name} /></div>
					<div className="detailsOfAvatar">
						<div>
							<h4>{allAvatarList[4].name}</h4>
						</div>
						<div className="buttonsContainer">
						<button className='navLinksView'> <NavLink path="relative" to="https://homecare.aihumans.ai/"><p>Chat</p><BsChatDotsFill size={20} color='white' /></NavLink> </button>
						</div>
					</div>
				</div>
				{allAvatarList.map((item, itemIndex) => {
					if (item.show === false)
						return null;
					else return (
						<div key={item.id} className="avatarCard">
							<div className="imageConatiner"><img src={item.thumbnailPath} alt={item.name} /></div>
							<div className="detailsOfAvatar">
								<div>
									<h4>{item.name}</h4>
									<p>({item.bottype})</p>
								</div>
								<div className="buttonsContainer">
									<button className="navLinksView" onClick={() => handleAvatarClick("main", item.bottype)}><p>Chat</p><BsChatDotsFill size={20} /></button>
									<button className="navLinksView" onClick={() => handleAvatarClick("Fullview", item.bottype)}><p>Full view</p><RxOpenInNewWindow size={20} /></button>
								</div>
							</div>
						</div>
					)
				})}
				<div className="avatarCard">
					<div className="imageConatiner"><img src={allAvatarList[5].thumbnailPath} alt={allAvatarList[5].name} /></div>
					<div className="detailsOfAvatar">
						<div>
							<h4>{allAvatarList[5].name}</h4>
						</div>
						<div className="buttonsContainer">
						<button className='navLinksView'> <NavLink path="relative" to="https://affinityshospice.aihumans.ai/"><p>Chat</p><BsChatDotsFill size={20} color='white' /></NavLink> </button>
						</div>
					</div>
				</div>
				<div className="avatarCard">
					<div className="imageConatiner"><img src={allAvatarList[6].thumbnailPath} alt={allAvatarList[6].name} /></div>
					<div className="detailsOfAvatar">
						<div>
							<h4>{allAvatarList[6].name}</h4>
						</div>
						<div className="buttonsContainer">
						<button className='navLinksView'> <NavLink path="relative" to="https://maple.aihumans.ai/"><p>Chat</p><BsChatDotsFill size={20} color='white' /></NavLink> </button>
						</div>
					</div>
				</div>
				<div className="avatarCard">
					<div className="imageConatiner"><img src={allAvatarList[7].thumbnailPath} alt={allAvatarList[7].name} /></div>
					<div className="detailsOfAvatar">
						<div>
							<h4>{allAvatarList[7].name}</h4>
						</div>
						<div className="buttonsContainer">
						<button className='navLinksView'> <NavLink path="relative" to="https://bluetreedental.aihumans.ai/"><p>Chat</p><BsChatDotsFill size={20} color='white' /></NavLink> </button>
						</div>
					</div>
				</div>
				<div className="avatarCard">
					<div className="imageConatiner"><img src={allAvatarList[1].thumbnailPath} alt={allAvatarList[1].name} /></div>
					<div className="detailsOfAvatar">
						<div>
							<h4>{allAvatarList[1].name}</h4>
						</div>
						<div className="buttonsContainer">
							<button className='navLinksView'> <NavLink path="relative" to="https://aihumans.ai/sophia/"><p>Chat</p><BsChatDotsFill size={20} color='white' /></NavLink></button>
						</div>
					</div>
				</div>
				<div className="avatarCard">
					<div className="imageConatiner"><img src={allAvatarList[2].thumbnailPath} alt={allAvatarList[2].name} /></div>
					<div className="detailsOfAvatar">
						<div>
							<h4>{allAvatarList[2].name}</h4>
						</div>
						<div className="buttonsContainer">
						<button className='navLinksView'> <NavLink path="relative" to="/health?orgId=org_3_helthcare_assistant&userId=a8716d42-b042-477c-9d71-d05443a0ba12&userName=Sylvie%20Silverstone&companyName=Mapple"><p>Chat</p><BsChatDotsFill size={20} color='white' /></NavLink> </button>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

export default AvatarList